import { APIError } from "./fetch";

export const bannerStatus = {
  GenericError: {
    banner: "Our system is currently offline. Please try again in a few minutes.",
  },
  SSONotSetUpError: {
    banner:
      "Single sign-on (SSO) is not configured on your account. Please contact your account administrator.",
  },
  GeneralIdentifierError: {
    banner:
      "General error retrieving what type of user you are. Please try again in a few minutes.",
  },
  BotDetectionError: {
    banner: "We couldn't verify that you're human. Please try again in a few minutes.",
  },
} as const;

export const loginError = (message: unknown): Error => {
  if (message instanceof Error) {
    return message;
  }
  if (typeof message === "string") {
    return new Error(message);
  }
  return new Error(message as string);
};

type Jsonable =
  | string
  | number
  | boolean
  | null
  | undefined
  | readonly Jsonable[]
  | { readonly [key: string]: Jsonable }
  | { toJSON(): Jsonable };

export class FetchError extends Error {
  public readonly context?: Jsonable;

  constructor(
    message: string,
    options: { error?: Error; context?: Jsonable; status?: number; errors?: APIError[] }
  ) {
    const { error: cause, context, status, errors } = options;
    super(message, { cause });

    this.name = this.constructor.name;
    this.context = context;
    let errorMsg = <p style={{ margin: "0 10px" }}>Your username or password is invalid.</p>;
    if (status === 500) {
      errorMsg = (
        <p style={{ margin: "0 10px" }}>
          Our system is currently offline. Please try again in a few minutes.
        </p>
      );
      // Signal sciences is currently killing the http1.1 connection instead of letting it complete
      // So even when they send us a 406 allegedly it's actually coming back as a network error in jquery terms it's
      // a status code 0
    } else if (status === 406 || status === 429 || status === 0) {
      errorMsg = (
        <p style={{ margin: "0 10px" }}>
          You have exceeded the maximum number of login attempts. Please try again later.
        </p>
      );
    } else if (status === 403) {
      errorMsg = (
        <p style={{ margin: "0 10px" }}>
          We are unable to log you in at this time, please check your email for further information.
        </p>
      );

      if (errors && errors.length > 0) {
        const responseErr = errors[0];
        if (responseErr.message === "exceeds max number of login attempts") {
          errorMsg = (
            <p style={{ margin: "0 10px" }}>
              You have exceeded the maximum number of login attempts. Please try again later.
            </p>
          );
        } else if (responseErr.help === "http://send.gd/1PemvHz") {
          errorMsg = (
            <p style={{ margin: "0 10px" }}>
              Your IP address is not allowed. See{" "}
              <a target="_blank" rel="noreferrer" href="http://send.gd/1PemvHz">
                http://send.gd/1PemvHz
              </a>{" "}
              for more details.
            </p>
          );
        }
      }
    }
    this.message = errorMsg as unknown as string;
  }
}
