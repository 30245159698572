import { useCallback, useEffect, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { formRedirectToUrl, isRelativeUrl } from "../navigationHelpers";
import { useSessionStorage } from "./useSessionStorage";

export const REDIRECT_TO = "redirect_to";

// useSessionRedirect wraps the logic for redirect_to
//
// decode to ensure if the original data was encoded to being with, so we're dealing with raw data.
// re-encode if we're passing it through as a param. This is because if the original data was
// already encoded, we don't want to double encode it.
//
// As a function of this hook, when you retrieve the session redirect or the encoded session redirect you delete the
// current value, this is its standard usage pattern.
export const useSessionRedirect = () => {
  const [searchParams] = useSearchParams();
  const [value, setValue, removeValue] = useSessionStorage<string>(REDIRECT_TO, "");

  useEffect(() => {
    const redirectTo = searchParams.get(REDIRECT_TO);
    if (redirectTo && !shouldIgnore(redirectTo)) {
      setValue(decodeURIComponent(redirectTo));
    }
  }, [searchParams, setValue]);

  const addSessionRedirect = useCallback(
    (route: string): string => {
      let redirectUrl;
      if (isRelativeUrl(value)) {
        redirectUrl = value ? joinPath(route, value) : route;
      } else {
        redirectUrl = formRedirectToUrl(value);
      }
      removeValue();
      return redirectUrl === "/" ? route : redirectUrl;
    },
    [value, removeValue]
  );

  const encodeSessionRedirect = useCallback(
    (route: string): string => {
      const encodedValue = encodeURIComponent(value);
      route = value ? joinPath(route, `?redirect_to=${encodedValue}`) : route;
      removeValue();
      return route;
    },
    [value, removeValue]
  );

  return useMemo(() => {
    return {
      addSessionRedirect,
      encodeSessionRedirect,
    };
  }, [addSessionRedirect, encodeSessionRedirect]);
};

/**
 * This joins the path of a URL and a fragment.
 * There doesn't appear to be a standardized way to do this in JS whereas there is in Node.
 * @param baseUrl
 * @param fragment
 */
function joinPath(baseUrl: string, fragment: string) {
  if (baseUrl.endsWith("/") && fragment.startsWith("/")) {
    return baseUrl.concat(fragment.slice(1));
  }
  if (baseUrl.endsWith("?") && fragment.startsWith("?")) {
    return baseUrl.concat(fragment.slice(1));
  }
  return baseUrl.concat(fragment);
}

const ignoreList = ["/logout", "/"];

/**
 * Tests should I ignore this redirectTo, list is defined in <code>ignoreList</code>
 * @param redirectTo the path
 */
function shouldIgnore(redirectTo: string) {
  for (const item of ignoreList) {
    if (normalizeCompare(redirectTo, item)) {
      return true;
    }
  }
  return false;
}

function normalizeCompare(x: string, y: string) {
  return x === y || decodeURIComponent(x) === decodeURIComponent(y);
}
