import { Turnstile } from "@marsidev/react-turnstile";
import React from "react";

interface BotDetectionProps {
  siteKey: string;
  onVerify?: (botDetectionToken: string) => void;
  onError?: (error: string) => void;
}

const BotDetection: React.FC<BotDetectionProps> = ({ siteKey, onVerify, onError }) => {
  const onErrorHandler = (error: string) => {
    const parsed = Number(error);
    // The error messages sent from the
    if (Number.isFinite(parsed)) {
      onError?.(
        " Error loading captcha. Please try refreshing the page. If the error persists, please contact support"
      );
      return;
    }
    onError?.(" Unknown error has occurred. If the error persists, please contact support");
  };

  return <Turnstile siteKey={siteKey} onSuccess={onVerify} onError={onErrorHandler} />;
};

export default BotDetection;
