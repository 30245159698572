// Adapted from https://github.com/braintree/sanitize-url/blob/main/src/index.ts

//  numeric character references in HTML markup representing an ascii character
//  matching their decimal and hexadecimal values
const htmlEntitiesRegex = /&#(\w+)(^\w|;)?/g;
const protocolAllowList = ["http:", "https:"];

// matching relative URLs that are malformed with multiple forward slashes or backslashes
const unsafeUrlRegex = /^\/(\/|\\)+|^.*?:\/\//i;

// matching ASCII control characters (non-printable characters)
// eslint-disable-next-line no-control-regex
const ctrlCharactersRegex = /[\u0000-\u001F\u007F-\u009F\u2000-\u200D\uFEFF]/gim;
const absoluteUrlSchemeRegex = /^([^:]+):/gm;

const redirectToUrlAllowList = [
  "https://support.sendgrid.com",
  "https://sendgrid.com",
  "https://staging.sendgrid.com",
  process.env.REACT_APP_MAKO_HOST,
  process.env.REACT_APP_MC_HOST,
];

export const formRedirectToUrl = (redirectToQueryParam: string) => {
  let redirectToUrl = sanitizeUrl(redirectToQueryParam);
  if (!isAllowListedUrl(redirectToUrl)) {
    return "/";
  }
  return redirectToUrl;
};

export const isRelativeUrl = (url: string) => {
  url = sanitizeUrl(url);
  return !absoluteUrlSchemeRegex.test(url);
};

export const sanitizeUrl = (url: string) => {
  url = decodeURIComponent(url);
  const sanitizedUrl = decodeHtmlCharacters(url).replace(ctrlCharactersRegex, "").trim();

  if (!sanitizedUrl) {
    return "/";
  }

  const urlSchemeParseResults = sanitizedUrl.match(absoluteUrlSchemeRegex);

  if (!urlSchemeParseResults) {
    // Check for potentially unsafe external links
    if (sanitizedUrl.match(unsafeUrlRegex)) return "/";

    return sanitizedUrl;
  }

  const urlScheme = urlSchemeParseResults[0];

  if (protocolAllowList.indexOf(urlScheme) === -1) {
    return "/";
  }

  return sanitizedUrl;
};

export const isAllowListedUrl = (url: string) => {
  try {
    if (isRelativeUrl(url)) {
      return true;
    }
    return redirectToUrlAllowList.includes(new URL(url).origin);
  } catch {
    return false;
  }
};

const decodeHtmlCharacters = (str: string) => {
  return str.replace(htmlEntitiesRegex, (_, dec) => {
    return String.fromCharCode(dec);
  });
};
