import { AppState, User } from "@auth0/auth0-react";
import { UnifiedProvisionPlanError } from "./constants";
import { UnifiedProvisionEligibilityResult } from "./context";
import { loginError } from "./error";
import {
  deleteAuthenticateToken,
  fetchAcceptInvite,
  FetchAcceptInviteResponse,
  fetchUnifiedLink,
  UnifiedProvisionPlanResponse,
} from "./fetch";

export const acceptTeammateInvite = async (id_token: string, appState?: AppState, user?: User) => {
  let makoAuthToken;
  if (appState && appState["token"]) {
    const acceptToken = appState["token"];

    // First we need to accept the invite via the acceptToken
    const acceptInviteResponse = await fetchAcceptInvite(acceptToken, {
      first_name: user?.given_name ?? "",
      last_name: user?.family_name ?? "",
      email: user?.email ?? "",
      username: user?.email ?? "",
    });
    // Check for errors to make sure we were able to accept the invite.
    if (!acceptInviteResponse.ok) {
      throw loginError("Error accepting invite please contact support");
    }
    // If there are no errors we want to get the makoAuthToken from the response.
    const response: FetchAcceptInviteResponse = await acceptInviteResponse.json();
    makoAuthToken = response.token;
  }

  // If we get a makoAuthToken back from acceptTeammateInvite, we will try to unify the teammate.
  if (makoAuthToken) {
    const unifiedLinkResponse = await fetchUnifiedLink(makoAuthToken, id_token);
    if (!unifiedLinkResponse.ok) {
      throw loginError("Error linking user please contact support");
    }
    // Delete the token since we are going to fetch a new linked one from session.
    await deleteAuthenticateToken(makoAuthToken);
  }
};

export const stripUndefined = (params: any) => {
  return Object.keys(params)
    .filter((k) => typeof params[k] !== "undefined")
    .reduce((acc, key) => ({ ...acc, [key]: params[key] }), {});
};

interface RolloutConfig {
  rollout_phase: number;
  base: number;
}

export const makeSureUserCanLink = (
  unifiedAccountLinking: RolloutConfig,
  response: UnifiedProvisionPlanResponse
): UnifiedProvisionEligibilityResult => {
  if (!response.eligible) {
    if (response.reason) {
      return {
        eligible: false,
        error: response.reason as UnifiedProvisionPlanError,
      };
    }
    return {
      eligible: false,
    };
  }
  const eligibleByPreviousRolloutPhase =
    response.rollout_phase < unifiedAccountLinking.rollout_phase;

  const eligibleByCurrentRolloutPhase =
    response.rollout_phase === unifiedAccountLinking.rollout_phase &&
    response.base <= unifiedAccountLinking.base;

  if (!eligibleByPreviousRolloutPhase && !eligibleByCurrentRolloutPhase) {
    return {
      eligible: false,
    };
  }
  return {
    eligible: true,
    email: response.email,
  };
};
