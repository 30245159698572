import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Button } from "../../Button";
import BotDetection from "../../components/BotDetection";
import { LOGO_ALT, TWILIO_SENDGRID_LOGO } from "../../constants";
import { signupContext } from "../../context";
import { Error as ErrorComponent } from "../../Error";
import { ExternalLink } from "../../external-navigate";
import { useFormState } from "../../hooks/useFormState";
import { TextInput } from "../../TextInput";
import { useSessionUsername, useUnifiedAuth } from "../../unified-auth";

const idpAuthErrorMessage = `Your login was unsuccessful, please try again. If issues persist, contact your company's Single Sign-On administrator.`;

interface IdentifierProps {
  botDetectionEnabled: boolean;
  botDetectionSitekey: string;
  botDetectionToken?: string;
}

export default function Identifier({
  botDetectionEnabled,
  botDetectionSitekey,
  botDetectionToken = "",
}: IdentifierProps) {
  const { navigate, isLoading, ssoLoginInfo: ssoInfo, isSSOLogin, error } = useUnifiedAuth();
  const [sessionUsername] = useSessionUsername();
  const [username, , changeUsername] = useFormState(sessionUsername);
  const [errorBanner, setErrorBanner] = useState<string>("");
  const [searchParams] = useSearchParams();
  const [token, setToken] = useState<string>(botDetectionToken);

  useEffect(() => {
    if (searchParams.has("error")) {
      setErrorBanner(idpAuthErrorMessage);
    } else {
      if (error instanceof Error) {
        setErrorBanner(error.message);
      }
    }
  }, [error, searchParams]);

  const formSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    await navigate({
      identifier: sanitizeUsername(username),
      ...(token && { bot_detection_token: token }),
    });
  };

  useEffect(() => {
    if (isSSOLogin) {
      document.forms[0].submit();
    }
  }, [isSSOLogin]);

  if (isSSOLogin) {
    return (
      <form method="post" name="frm" action={ssoInfo?.idp_url} style={{ display: "none" }}>
        <input type="hidden" name="SAMLRequest" value={ssoInfo?.saml_request} />
        <input type="hidden" name="RelayState" value={ssoInfo?.relay_state} />
        <input type="submit" />
      </form>
    );
  }
  return (
    <main className="login">
      {errorBanner && <ErrorComponent message={errorBanner} />}

      <div className="login-container">
        <div data-role="login-modals" className="login-modals">
          <div className="login-modal">
            <div className="login-modal-table">
              <div className="login-modal-table-row">
                <div className="login-modal-body">
                  <div className="login-logo">
                    <img alt={LOGO_ALT} src={TWILIO_SENDGRID_LOGO} />
                  </div>
                  <form data-role="login-form" className="login-form" onSubmit={formSubmit}>
                    <div className="row">
                      <div className="col-8 col-start-3">
                        <TextInput
                          label="Username (this may be your email address)"
                          id="username"
                          value={username}
                          autoFocus={true}
                          autoComplete="username"
                          onChange={changeUsername}
                          containerRole={"usernameContainer"}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-8 col-start-3">
                        {botDetectionEnabled && (
                          <BotDetection
                            siteKey={botDetectionSitekey}
                            onVerify={setToken}
                            onError={setErrorBanner}
                          />
                        )}
                      </div>
                    </div>
                    <div className="login-btn">
                      <Button
                        dataRole="continue-btn"
                        className="login-sso-btn"
                        isLoading={isLoading}
                        isDisabled={isLoading || (botDetectionEnabled && token.trim() === "")}>
                        Continue
                      </Button>
                    </div>
                  </form>
                </div>
              </div>

              <div className="login-modal-table-row">
                <div className="login-modal-footer" data-role="pricing-container">
                  Don&apos;t have a Twilio SendGrid account?&nbsp;
                  <ExternalLink to={"/"} context={signupContext}>
                    Sign up now!
                  </ExternalLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

const sanitizeUsername = (username: string): string => username.trim();
